<template>
  <div class="gallery">
    <mobile class="mobile" />
    <desktop class="desktop" />
    <slider />
  </div>
</template>

<script>
import slider from "../components/slider.vue";
import mobile from "../components/mobile.vue";
import desktop from "../components/desktop.vue";
export default {
  components: {
    slider,
    mobile,
    desktop,
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
.gallery {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(black, 0.9), rgba(black, 0.8)),
    url("~@/assets/images/background/collage1.jpg") center no-repeat;
  background-size: cover;
}
</style>