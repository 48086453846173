<template>
  <div class="about">
    <mobile class="mobile" />
    <desktop class="desktop" />
    <div class="wrapper">
      <div class="slide" v-slidefade2>
        <img
          loading="lazy"
          src="@/assets/images/annotate/about.jpg"
          alt=""
          class="gallery-item"
        />
        <img
          loading="lazy"
          src="@/assets/images/about/kofi.jpg"
          alt=""
          class="gallery-item"
        />
        <img
          loading="lazy"
          src="@/assets/images/about/cig.jpg"
          alt=""
          class="gallery-item"
        />
        <img
          loading="lazy"
          src="@/assets/images/about/hand.jpg"
          alt=""
          class="gallery-item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mobile from "../components/mobile.vue";
import desktop from "../components/desktop.vue";
export default {
  components: {
    mobile,
    desktop,
  },
  methods: {},
};
</script>



<style lang="scss" scoped>
.about {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(black, 0.9), rgba(black, 0.8)),
    url("~@/assets/images/background/collage1.jpg") center no-repeat;
  background-size: cover;
}
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 100vh;
  padding-left: 20%;
  @media (max-width: 1100px) {
    padding-left: 0%;
    padding-top: 110px;
  }
}
.slide {
  img {
    height: 100vh;
    transition: filter 2s;
    color: white;
    text-align: center;
    display: inline-block;
    padding: 1px;
    @media (max-width: 1100px) {
      height: 70vh;
      padding-left: 0%;
    }
  }
}
</style>
